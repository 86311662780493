import { render, staticRenderFns } from "./IconTrophy.vue?vue&type=template&id=d61eb3a6&"
import script from "./IconTrophy.vue?vue&type=script&lang=js&"
export * from "./IconTrophy.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports