<style lang="scss"></style>

<template>
  <svg
    width="23"
    height="20"
    viewBox="0 0 23 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.31429 18.147C7.65569 18.147 7.76766 17.8983 7.5405 17.2712L5.604 10.8981L20.5106 2.05466"
      fill="#C8DAEA"
    />
    <path
      d="M8.31396 18.1469C8.82214 18.1469 9.04659 17.9145 9.33032 17.6387L12.0406 15.0033L8.65986 12.9646"
      fill="#A9C9DD"
    />
    <path
      d="M8.65958 12.9652L16.8514 19.0175C17.7863 19.5333 18.4608 19.2661 18.6937 18.1497L22.0282 2.43629C22.3695 1.0676 21.5065 0.446605 20.6121 0.852639L1.032 8.40264C-0.304511 8.93877 -0.296549 9.68443 0.78841 10.0166L5.8131 11.585L17.4458 4.24608C17.995 3.91306 18.4991 4.09193 18.0854 4.45918"
      fill="url(#paint0_linear_870:10697)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_870:10697"
        x1="14.589"
        y1="8.83198"
        x2="17.7613"
        y2="16.0598"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EFF7FC" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconTg',
  components: {},
  data() {
    return {}
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
