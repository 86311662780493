<style lang="scss"></style>

<template>
  <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.1" cx="17" cy="17" r="16.7569" fill="white" stroke="#242424" stroke-width="0.486111"/>
    <path d="M23.75 11H21.5V10.25C21.5 10.0511 21.421 9.86032 21.2803 9.71967C21.1397 9.57902 20.9489 9.5 20.75 9.5H13.25C13.0511 9.5 12.8603 9.57902 12.7197 9.71967C12.579 9.86032 12.5 10.0511 12.5 10.25V11H10.25C10.0511 11 9.86032 11.079 9.71967 11.2197C9.57902 11.3603 9.5 11.5511 9.5 11.75V14C9.5 14.7956 9.81607 15.5587 10.3787 16.1213C10.9413 16.6839 11.7044 17 12.5 17H13.655C14.3334 17.7569 15.248 18.2618 16.25 18.4325V20H15.5C14.9033 20 14.331 20.2371 13.909 20.659C13.4871 21.081 13.25 21.6533 13.25 22.25V23.75C13.25 23.9489 13.329 24.1397 13.4697 24.2803C13.6103 24.421 13.8011 24.5 14 24.5H20C20.1989 24.5 20.3897 24.421 20.5303 24.2803C20.671 24.1397 20.75 23.9489 20.75 23.75V22.25C20.75 21.6533 20.5129 21.081 20.091 20.659C19.669 20.2371 19.0967 20 18.5 20H17.75V18.4325C18.752 18.2618 19.6666 17.7569 20.345 17H21.5C22.2956 17 23.0587 16.6839 23.6213 16.1213C24.1839 15.5587 24.5 14.7956 24.5 14V11.75C24.5 11.5511 24.421 11.3603 24.2803 11.2197C24.1397 11.079 23.9489 11 23.75 11ZM12.5 15.5C12.1022 15.5 11.7206 15.342 11.4393 15.0607C11.158 14.7794 11 14.3978 11 14V12.5H12.5V14C12.5017 14.5113 12.5904 15.0185 12.7625 15.5H12.5ZM18.5 21.5C18.6989 21.5 18.8897 21.579 19.0303 21.7197C19.171 21.8603 19.25 22.0511 19.25 22.25V23H14.75V22.25C14.75 22.0511 14.829 21.8603 14.9697 21.7197C15.1103 21.579 15.3011 21.5 15.5 21.5H18.5ZM20 14C20 14.7956 19.6839 15.5587 19.1213 16.1213C18.5587 16.6839 17.7956 17 17 17C16.2044 17 15.4413 16.6839 14.8787 16.1213C14.3161 15.5587 14 14.7956 14 14V11H20V14ZM23 14C23 14.3978 22.842 14.7794 22.5607 15.0607C22.2794 15.342 21.8978 15.5 21.5 15.5H21.2375C21.4096 15.0185 21.4983 14.5113 21.5 14V12.5H23V14Z" fill="#00C1F5"/>
  </svg>
</template>

<script>
export default {
  name: 'IconBag',
  components: {},
  data() {
    return {}
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
