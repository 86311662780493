<style lang="scss" scoped>
.chat {
  @apply flex md:items-center flex-col md:flex-row;
  .descr {
    @apply font-graphik_lv_regular mt-4 md:mt-0;
    font-size: 14px;
    line-height: 20px;
    color: #dadde6;
    & a {
      color: #00c1f5;
    }
  }
  &.left {
    .descr {
      @apply order-last md:ml-10 mr-0 text-left;
    }
  }
  &.right {
    .descr {
      @apply order-last md:order-first text-left md:text-right md:mr-10;
    }
  }
}
.btn-link {
  @apply font-graphik_lv_regular flex items-center px-6 whitespace-nowrap justify-center;
  background: #1b8cc5;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  height: 44px;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #ffffff;
  &:hover {
    transform: scale(1.02);
  }
}
@screen md {
  .chat {
    .descr {
      font-size: 18px;
      line-height: 30px;
    }
  }
  .btn-link {
    height: 50px;
  }
}
</style>

<template>
  <div class="chat" :class="variant">
    <div class="descr">
      Общайся с другими игроками в нашем чате<br />в Telegram:
      <a :href="link" target="_blank">@opendatabattle</a>
    </div>
    <a class="btn-link" :href="link" target="_blank"
      ><icon-tg class="mr-3" />Чат для игроков</a
    >
  </div>
</template>

<script>
import IconTg from './Icons/IconTg.vue'
export default {
  name: 'TgChat',
  components: { IconTg },
  data() {
    return {
      link: 'tg://resolve?domain=opendatabattle',
    }
  },
  props: {
    variant: {
      type: String,
      default: 'right',
      validator: function (val) {
        return ['left', 'right'].includes(val)
      },
    },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
