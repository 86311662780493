<style lang="scss"></style>

<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99993 0.666748C7.08222 0.667899 6.18521 0.939607 5.42111 1.44789C4.65702 1.95617 4.05974 2.67846 3.704 3.52442C3.34826 4.37038 3.24983 5.30245 3.42105 6.20405C3.59226 7.10565 4.02551 7.93675 4.6666 8.59341V14.6667C4.66694 14.7804 4.69633 14.8921 4.75197 14.9911C4.80761 15.0902 4.88766 15.1735 4.98452 15.2329C5.08137 15.2924 5.19181 15.3261 5.30536 15.3308C5.4189 15.3356 5.53178 15.3112 5.63326 15.2601L7.99993 14.0801L10.3666 15.2601C10.4595 15.3077 10.5622 15.3328 10.6666 15.3334C10.7914 15.3338 10.9138 15.2992 11.0199 15.2334C11.116 15.1734 11.1952 15.0898 11.25 14.9907C11.3048 14.8915 11.3335 14.78 11.3333 14.6667V8.59341C11.9744 7.93675 12.4076 7.10565 12.5788 6.20405C12.75 5.30245 12.6516 4.37038 12.2959 3.52442C11.9401 2.67846 11.3428 1.95617 10.5787 1.44789C9.81464 0.939607 8.91764 0.667899 7.99993 0.666748ZM9.99993 13.5867L8.29993 12.7401C8.20688 12.6932 8.10413 12.6688 7.99993 12.6688C7.89573 12.6688 7.79298 12.6932 7.69993 12.7401L5.99993 13.5867V9.54675C6.42188 9.74599 6.87132 9.88082 7.33326 9.94675V10.6667C7.33326 10.8436 7.4035 11.0131 7.52852 11.1382C7.65355 11.2632 7.82312 11.3334 7.99993 11.3334C8.17674 11.3334 8.34631 11.2632 8.47133 11.1382C8.59636 11.0131 8.66659 10.8436 8.66659 10.6667V9.94675C9.12854 9.88082 9.57798 9.74599 9.99993 9.54675V13.5867ZM7.99993 8.66675C7.34066 8.66675 6.69619 8.47125 6.14803 8.10498C5.59986 7.73871 5.17262 7.21811 4.92033 6.60903C4.66804 5.99994 4.60203 5.32972 4.73064 4.68311C4.85926 4.03651 5.17673 3.44257 5.64291 2.97639C6.10908 2.51022 6.70302 2.19275 7.34963 2.06413C7.99623 1.93551 8.66645 2.00152 9.27554 2.25382C9.88463 2.50611 10.4052 2.93335 10.7715 3.48151C11.1378 4.02968 11.3333 4.67414 11.3333 5.33341C11.3333 6.21747 10.9821 7.06532 10.357 7.69044C9.73183 8.31556 8.88398 8.66675 7.99993 8.66675Z"
      fill="#00CDB6"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconReward',
  components: {},
  data() {
    return {}
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
