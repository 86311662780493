<style lang="scss">
.shedule {
  @apply lg:flex lg:justify-around items-start;
  margin-bottom: 68px;
  & ul {
    & li {
      @apply flex justify-start items-start text-left font-graphik_lv_regular;
      margin-bottom: 30px;

      font-size: 12px;
      line-height: 20px;
      color: #dadde6;
      & span {
        color: #00b4e3;
      }
    }
  }
  &__time {
    @apply font-druk_text_cy;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    margin-right: 32px;
    margin-top: -5px;
    color: #fff;
    width: 45px;
  }
  &__title {
    @apply font-druk_text_cy;
    font-size: 20px;
    text-align: left;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 32px;
    & span {
      color: #00c1f5;
    }
    & svg {
      display: inline;
      vertical-align: bottom;
      margin-right: 12px;
    }
  }
}
@screen lg {
  .shedule {
    &__list {
      width: 500px;
      padding-right: 87px;
      border-right: 1px solid rgba(255, 255, 255, 0.15);
      &--second {
        width: 413px;
      }
    }
    & ul {
      & li {
        width: 100%;
        font-size: 16px;
        line-height: 28px;
        & p {
        }
      }
    }
    &__title {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 72px;
    }
    &__time {
      font-size: 40px;
      line-height: 52px;
      width: 72px;
    }
  }
}
</style>

<template>
  <div class="shedule__wrapper">
    <p class="shedule__title">
      <IconTrophy />
      <span>30 октября</span> - начало финала
    </p>
    <div class="shedule">
      <ul class="shedule__list">
        <li>
          <time class="shedule__time">10:00</time>
          <p>Открывается основная задача, на решение даётся 28 часов</p>
        </li>
      </ul>
      <ul class="shedule__list--second">
        <li>
          <time class="shedule__time">13:00</time>
          <p>Открывается дополнительная задача, на решение даётся 6 часов</p>
        </li>
      </ul>
    </div>
    <p class="shedule__title">
      <IconTrophy />
      <span>31 октября</span> - трансляция и награждение финалистов
    </p>
    <div class="shedule">
      <ul class="shedule__list">
        <li v-for="(item, ind) in getFirstHalfArray" :key="ind">
          <time class="shedule__time" :datetime="item.time">{{ item.time }}</time>
          <p v-html="item.title"></p>
        </li>
      </ul>
      <ul class="shedule__list--second">
        <li v-for="(item, ind) in getSecondHalfArray" :key="ind">
          <time class="shedule__time" :datetime="item.time">{{ item.time }}</time>
          <p v-html="item.title"></p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import IconTrophy from './Icons/IconTrophy.vue'
export default {
  name: 'SheduleTable',
  components: { IconTrophy },
  data() {
    return {}
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {
    getFirstHalfArray() {
      return this.list.slice(0, this.list.length / 2)
    },
    getSecondHalfArray() {
      return this.list.slice(this.list.length / 2, this.list.length)
    },
  },
}
// eslint-disable-next-line prettier-vue/prettier
</script>
