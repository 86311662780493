<style lang="scss">
.issue-card {
  @apply relative flex m-2 flex-col font-druk_text_cy bg-contain bg-no-repeat;
  background-color: rgba(16, 16, 16, 0.75);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 16px;
  padding: 12px 16px 24px 16px;
  width: 100%;
  color: #ffffff;
  height: 331px;
  background-position: center top;
  transition: 0.25s;
  &:hover {
    transform: scale(1.02);
    background-color: rgba(16, 16, 16, 0.9);
  }

  &--plug {
    width: 349px;
    height: 411px;
    margin-left: 10px;
    margin-right: 10px;
    opacity: 0.4;
    cursor: default;
  }
  &--plug {
    @apply cursor-pointer;
    &__title {
      @apply text-32 mb-4;
    }

    &__descr {
      @apply text-18;
      line-height: 28px;
    }

    &__difficulty {
      @apply text-16;
    }
    &:hover {
      @apply cursor-auto;
      transform: none;
    }
  }

  &--special {
    @apply absolute;
    top: -25px;
    z-index: 1;
    background-color: #0f0f0f;
    width: 403px;
    height: 465px;
    margin-right: 66px;
    box-shadow: 19px 0px 20px 0px #141414;
    &__button {
      display: block;
    }
    &:hover {
      @apply cursor-auto;
      transform: none;
    }
  }

  &__header {
    @apply flex-grow flex justify-between;
  }
  &__title {
    @apply mb-2;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
  }
  &__descr {
    @apply font-graphik_lv_regular;
    font-size: 14px;
    line-height: 20px;
  }
  &__status {
    @apply flex justify-center items-center pl-2 pr-3 w-max;
    background: rgba(0, 205, 182, 0.01);
    border: 1px solid rgba(0, 205, 182, 0.32);
    border-radius: 4px;
    height: 24px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: rgba(0, 205, 182, 0.8);
    & svg {
      @apply ml-3;
    }
  }

  &__button {
    @apply mt-6;
    max-width: 179px;
    display: none;
  }
}

@screen sm {
  .issue-card {
    width: calc(100% / 2 - 16px);

    &--plug {
      width: 349px;
    }

    &--special {
      width: 403px;
    }
  }
}

@screen lg {
  .issue-card {
    width: calc(100% / 3 - 16px);

    &--plug {
      width: 349px;
    }

    &--special {
      width: 403px;
    }
  }
}
</style>

<template>
  <router-link
    :to="{ name: 'IssuesItem', params: { id: id } }"
    class="issue-card"
    :style="{ backgroundImage: `url('${image}')` }"
  >
    <div class="issue-card__header">
      <difficulty-value :difficulty="difficulty" />
      <div v-if="isComplete" class="issue-card__status">
        Выполнено
        <icon-reward />
      </div>
    </div>
    <div class="issue-card__content">
      <div class="issue-card__title">{{ title }}</div>
      <div class="issue-card__descr">{{ description }}</div>
      <slot />
    </div>
  </router-link>
</template>

<script>
import IconReward from './Icons/IconReward.vue'
import DifficultyValue from './UI/DifficultyValue.vue'

export default {
  name: 'IssueCard',
  components: { IconReward, DifficultyValue },
  data() {
    return {}
  },
  props: {
    ...DifficultyValue.props,
    id: { type: String },
    title: { type: String },
    description: { type: String },
    isComplete: { type: Boolean },
    image: { type: String },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
